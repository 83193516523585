import React, { useState } from 'react';
import CopyButton from './CopyButton';

const Mheader = ({uname,setRouter}) => {
    const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
 
  return (
    <header className="bg-black z-[1] w-full absolute p-4">
        
       
      <div className="container mx-0 flex justify-between items-end">
     
        
        <button
          className="text-white md:hidden"
          onClick={toggleMenu}
        >
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>
        <div className="text-white text-xl font-bold">
          Hi, {uname.first_name}
          {/* <p>{user ?           ({user})           : ("hi")}</p> */}

        </div>
        
        
      </div>
      <div
          className={`${isOpen ? 'block' : 'hidden'}  flex justify-start mt-10 items-center space-x-6 h-screen w-screen`}
        >
             
          <ul className="md:flex md:space-x-6  justify-center">
            <li className="my-4 md:mt-0 ">
              <button className="text-white block" onClick={() => {setIsOpen(!isOpen) ;setRouter('Home')}}>
              Home
              </button>
            </li>
            <hr/>
            <li className="my-4 md:mt-0">
            <button className="text-white block" onClick={() => {setIsOpen(!isOpen) ;setRouter('Friends')}}>
              Friends
              </button>
            </li>
            
            <hr/>

            <li className="my-4 md:mt-0">
            <CopyButton textToCopy={`https://t.me/type_luna_bot?start=${uname.id}`}/>

            </li>
            <hr/>
            <li className="my-4 md:mt-0">
            <button className="text-white block" onClick={() => {setIsOpen(!isOpen) ;setRouter('Contact')}}>
              About us
              </button>
            </li>
            <hr/>
            </ul>
        </div>
    </header>
  );
};

export default Mheader;