import React,{useState,useEffect} from 'react'
import Card from './Card'
import Mheader from './Mheader' 
import axios from 'axios'
import Confetti from 'react-confetti';
import RiveBackground from './RiveBackground';

function Mmain() {

const defaultUser = {id: -1,first_name: "Xexe",last_name: "",telegram_id: "123",telegram_username: "",phone_number: "",balance: "0",tap: "1"}
const [counter,setCounter] = useState(0);
const [router,setRouter] = useState('Home')
const [showConfetti, setShowConfetti] = useState(false);  
const [userInfo, setUserInfo] = useState(defaultUser);
const [resPonse,setResponse]=useState('null');
const [ero,setEro]=useState('ok');
const [telegramId, setTelegramId] = useState(null);
const [tap, setTap] = useState('0');

const handleClick = async () =>{
  setCounter(counter+Number(tap));
  try {
    const response  = await axios.post('https://api.kuchisabishii.uz/tgu/'+resPonse.telegram_id+'/', {
      id:String(resPonse.id),
      telegram_id: resPonse.telegram_id,
      first_name: resPonse.first_name,
      last_name: resPonse.last_name,
      username: resPonse.username,
      phone_number: resPonse.phone_number, 
      balance : String(counter+Number(tap)),
      tap:resPonse.tap,
      })
    console.log(response)
    console.log(telegramId)
  }
  catch (error) {
    console.log(error.response.status)
  }
}
//userinfo
useEffect(() => {
  async function initialize() {    
    try {
      await window.Telegram.WebApp.ready();
      const user = window.Telegram.WebApp.initDataUnsafe.user;
      if (user) {
        setUserInfo(user);
        setTelegramId(user.id);
        if(resPonse==='null'){
          try {
            const response = await axios.get(`https://api.kuchisabishii.uz/tgid/${user.id}`) 
            if(response.data){
              console.log(response.status)
            }
          } 
          catch (error) {
            if (error.response && error.response.status === 404) {
              console.log("s"+error.response.status) 
              setEro(error.response.status)
            }
          }
        }
        if(ero!=="ok"){
          console.log('ero#'+ ero)
        }
        if (ero===404){
          try {
            const response  = await axios.post('https://api.kuchisabishii.uz/users/', {
                telegram_id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                username: user.username,
                phone_number: '', 
                balance : '0',
                tap:'1',
                })  
            if(response.status===201){
              setShowConfetti(true);setTimeout(() => setShowConfetti(false), 3000);
            }
          }
          catch (error) {
            console.log(error.response.status)
          }
        }
        const response = await axios.get(`https://api.kuchisabishii.uz/tgid/${user.id}/`);
        setTelegramId(Number(response.data.telegram_id));
        setResponse(response.data);
        setCounter(JSON.parse(response.data.balance));
        setTap(response.data.tap);
      }
      else { 
        try { 
          const response = await axios.get(`https://api.kuchisabishii.uz/tgid/122/`);
          if(response.data){
            setTelegramId(Number(response.data.telegram_id));
            setResponse(response.data);
            setCounter(JSON.parse(response.data.balance));
            setTap(response.data.tap);
          }
        } 
        catch (error) {
          if (error.response && error.response.status === 404) {
            console.log(error.response.status)
          }
        }
      }
    }
    catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  }
initialize();
}, [ero]);
  
return (
  <div>
    <Mheader uname={userInfo?(resPonse):("hehe")} setRouter={setRouter} />   
      <div className='flex justify-center items-center w-screen h-screen'>
      {
      router==="Home"?(
      <>
        {showConfetti && <Confetti />}
        <RiveBackground/>
        <div className='w-30 h-30 bg-none'>
          <Card onClick={handleClick} title={counter} />
        </div>
      </>
      ):(
        "its not home"
      )}
    </div>
  </div>
)}

export default Mmain