import React from 'react';
import ReactDOM from 'react-dom';
import ScreenSizeComponent from './ui/ScreenSizeComponent';
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div > 
        <ScreenSizeComponent/>
    </div>
  
);


