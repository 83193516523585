// import React, { useState, useEffect } from 'react'

// import axios from 'axios'
// import Button from './Button';
// const App=()=>{
//     const defaultUser = {
//         id: 5,
//         first_name: "Hi",
//         last_name: "",
//         telegram_id: "6638935526",
//         telegram_username: "",
//         phone_number: "",
//         balance: "0",
//         tap: "1"
//     }
//     const [userInfo, setUserInfo] = useState(defaultUser);
//       useEffect(() => {
//     async function initialize() {
//         try {
//             if(userInfo==defaultUser){
//             const response = await axios.get(`https://api.kuchisabishii.uz/users/1`);
//             setUserInfo(JSON.stringify(response.data));  // Set data from response
//             }
//         } catch (error) {
//             alert(error)
//             console.error("Error fetching user data:", error);
//           }
//         }
//     initialize();
//   }, [userInfo]);

//   return (
//     <div className="h-screen flex justify-center items-center bg-gray-900">
//     {userInfo==defaultUser?("hehe"):( JSON.parse(userInfo).first_name)}
//     <Button>salom</Button>
//     </div>
     
//   );
// }

// export default App



// import React, { useState, useEffect } from 'react'

// import axios from 'axios'
// import Button from './Button';
// const App=()=>{


//   return (
//     <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
//     <RiveComponent src="path/to/your/animation.riv" style={{ width: '100%', height: '100%' }} />
//   </div>
     
//   );
// }

// export default App
import React from 'react'
import RiveBackground from './RiveBackground'

function App() {
  return (
    <div>
      
      <RiveBackground/>
    </div>
  )
}

export default App