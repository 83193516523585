import React from 'react';
import useWindowSize from './useWindowSize'; 
import Mmain from './mob/Mmain';
import App from './mob/App';
import Map from './mob/Map';

const ScreenSizeComponent = () => {
  const { width, height } = useWindowSize();

  return (
    <div>
      
      {width <600 ? 
      (
        <Mmain/>
     ) : width >1024 ?
      (  <Map/>):
      ("xexe")}
      
    </div>
  );
};

export default ScreenSizeComponent;