import React, { useState } from 'react';

const CopyButton = ({ textToCopy }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
    if (navigator.share) {
        try {
          await navigator.share({
            title: "Luna Token Fake!",
            text: "Invaite Your Friends & Get Your Bonus)",
            url: textToCopy,
          });
          console.log('Share successful');
        } catch (error) {
          console.log('Share failed', error);
        }
      } else {
        console.log('Web Share API not supported');
      }
  };

  return (
    <div className='z-[2] text-white '>
      <button onClick={handleCopy} >{copySuccess==="Copied!"?("Copied!"):"Invite a friend!"}</button>
      
    </div>
  );
};

export default CopyButton;
