import React, { useEffect, useRef } from "react";

const getContainer = () => {
  const id = "_coolMode_effect";
  let existingContainer = document.getElementById(id);

  if (existingContainer) {
    return existingContainer;
  }

  const container = document.createElement("div");
  container.setAttribute("id", id);
  container.setAttribute(
    "style",
    "overflow:hidden; position:fixed; height:100%; top:0; left:0; right:0; bottom:0; pointer-events:none; z-index:2147483647"
  );

  document.body.appendChild(container);

  return container;
};

let instanceCounter = 0;

const applyParticleEffect = (element, options) => {
  instanceCounter++;

  const defaultParticle = "circle";
  const particleType = options?.particle || defaultParticle;
  const sizes = [15, 20, 25, 35, 45];
  const limit = 45;

  let particles = [];
  let mouseX = 0;
  let mouseY = 0;

  const container = getContainer();

  const generateParticle = () => {
    const size = sizes[Math.floor(Math.random() * sizes.length)];
    const speedHorz = options?.speedHorz || Math.random() * 10;
    const speedUp = options?.speedUp || Math.random() * 25;
    const spinVal = Math.random() * 360;
    const spinSpeed = Math.random() * 35 * (Math.random() <= 0.5 ? -1 : 1);
    const top = mouseY - size / 2;
    const left = mouseX - size / 2;
    const direction = Math.random() <= 0.5 ? -1 : 1;

    const particle = document.createElement("div");

    if (particleType === "circle") {
      const circle = document.createElement("div");
      circle.style.width = `${size}px`;
      circle.style.height = `${size}px`;
      circle.style.borderRadius = "50%";
      circle.style.backgroundColor = `hsl(${Math.random() * 360}, 70%, 50%)`;
      
      // If particle option is a valid image URL, set it as the background image
      if (options?.particle && options.particle !== "circle") {
        circle.style.backgroundImage = `url(${options.particle})`;
        circle.style.backgroundSize = "cover";
        circle.style.backgroundPosition = "center";
      }
      
      particle.appendChild(circle);
    } else {
      particle.innerHTML = `<img src="${particleType}" width="${size}" height="${size}" style="border-radius: 50%">`;
    }

    particle.style.position = "absolute";
    particle.style.transform = `translate3d(${left}px, ${top}px, 0px) rotate(${spinVal}deg)`;

    container.appendChild(particle);

    particles.push({
      direction,
      element: particle,
      left,
      size,
      speedHorz,
      speedUp,
      spinSpeed,
      spinVal,
      top,
    });
  };

  const refreshParticles = () => {
    particles.forEach((p) => {
      p.left -= p.speedHorz * p.direction;
      p.top -= p.speedUp;
      p.speedUp = Math.min(p.size, p.speedUp - 1);
      p.spinVal += p.spinSpeed;

      if (p.top >= Math.max(window.innerHeight, document.body.clientHeight) + p.size) {
        particles = particles.filter((o) => o !== p);
        p.element.remove();
      }

      p.element.style.transform = `translate3d(${p.left}px, ${p.top}px, 0px) rotate(${p.spinVal}deg)`;
    });
  };

  let animationFrame;
  const loop = () => {
    refreshParticles();
    animationFrame = requestAnimationFrame(loop);
  };

  loop();

  const isTouchInteraction = "ontouchstart" in window;

  const tap = isTouchInteraction ? "touchstart" : "mousedown";

  const updateMousePosition = (e) => {
    if ("touches" in e) {
      mouseX = e.touches[0].clientX;
      mouseY = e.touches[0].clientY;
    } else {
      mouseX = e.clientX;
      mouseY = e.clientY;
    }
  };

  const tapHandler = (e) => {
    updateMousePosition(e);
    generateParticle();
  };

  element.addEventListener(tap, tapHandler);
  
  return () => {
    element.removeEventListener(tap, tapHandler);

    const interval = setInterval(() => {
      if (animationFrame && particles.length === 0) {
        cancelAnimationFrame(animationFrame);
        clearInterval(interval);

        if (--instanceCounter === 0) {
          container.remove();
        }
      }
    }, 500);
  };
};

export const CoolMode = ({ children, options }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      return applyParticleEffect(ref.current, options);
    }
  }, [options]);

  return React.cloneElement(children, { ref });
};
