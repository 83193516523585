import React from 'react';
import { CoolMode } from './cool-mode';
const Card = ({ title,onClick }) => {
  return (
    <CoolMode options={{ particle: 'https://my.uncr.uz/coin.png' }}>
    <div onClick={onClick} className=" z-[1] rounded-full w-36 h-36 overflow-hidden shadow-lg  shadow-yellow-400">
      <div className="px-6  border-2 opacity-70 border-solid rounded-full h-full border-gray-400 flex justify-center items-center  bg-gradient-to-br from-yellow-300 via-blue-500 to-yellow-700 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
      </div>
    </div>
    </CoolMode>
  );
};

export default Card;