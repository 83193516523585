import React from 'react'
import { useRive, useStateMachineInput } from 'rive-react'
import Spiral from '../../spiral.riv'

function RiveBackground() {
    const STATE_MACHINE_NAME = "State Machine 1"
    const STATE_MACHINE_INPUT = "Boolean 1"
    const {rive, RiveComponent} = useRive({
      src:Spiral,
       artboard:"Artboard",
      stateMachines:STATE_MACHINE_NAME,
      autoplay:true
      
    })
    const onClickInput =  useStateMachineInput(rive,STATE_MACHINE_NAME,STATE_MACHINE_INPUT)
    const triggerAnimation = (animationName) => {
      rive?.play(animationName);
    };
    return (
      <div className='w-screen  absolute z-[0] flex fit bg-blue-950 h-screen justify-center items-center'>
      <RiveComponent 
        style={{transform: 'scale(2)',  height: "100%", width: "100%" }} 
        onMouseOver={() => { 
          if (onClickInput) { // Check if onClickInput is not null
            onClickInput.fire(); 
            triggerAnimation("hover"); 
          } else {
            console.error("onClickInput is not initialized.");
          }
        }} 
      />
    </div>
    )
  }
export default RiveBackground